<template>
  <div :class="className">
    <div class="tips">
      <div class="tips-icon" :style="props?.isWrap ? iconObj?.transform : null">
        <slot name="icon" v-if="$slots.icon"></slot>
        <Icon :name="iconObj.name" class="tip-icon" v-else></Icon>
      </div>
      <div class="content">
        <div class="title" v-if="$slots.title">
          <slot name="title"></slot>
        </div>
        <div class="describe" v-if="$slots.describe">
          <slot name="describe"></slot>
        </div>
      </div>
    </div>
    <a-button size="middle" class="notice-action" @click="onClick" v-if="$slots.action">
      <slot name="action"></slot>
    </a-button>
    <span class="notice-close" @click="onClick" v-if="$slots.close">
      <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-close-cu"></use>
        </svg>
    </span>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import Icon from './Icon.vue';
const props = defineProps([
  'type',//normal紫色，success绿色，warning蓝色，urgent黄色,error红色，现在暂时只有normal跟urgent
  'isWrap',//同时存在标题跟描述时使用，0，1
]);
const emit=defineEmits(['click']);

const className = computed(() => {
  let publicClass = "report-notice";
  if (props.type) {
    publicClass += ` ${props.type}`;
  } else {
    publicClass += 'normal';
  }
  if (props.class) {
    publicClass += ` ${props.class}`;
  }
  return publicClass;
});
const iconObj=computed(()=>{
  let obj={};
  switch(props.type){
    case 'normal':
    obj.name='info-tianchong';
    break;
    case 'success':
    obj.name='success-tianchong';
    break;
    case 'warning':
    obj.name='info-tianchong';
    break;
    case 'urgent':
    obj.name='a-warring-tianchong3';
    break;
    case 'error':
    obj.name='a-error-tianchong5';
    break;
  }
  if(props?.isWrap){//后期如果需要，iswrap可以为{x:?,y:?},现在暂时用1，0
    obj.transform={
      'transform':'translateY(-10px)'
    }
  }
  return obj;

})
const onClick=e=>{
  emit('click')
}
</script>
<style lang="less" scoped>
.report-notice {
  margin-bottom: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px 6px 11px;

  .tips {
    display: flex;
    align-items: center;

    .tips-icon {
      // transform: translateY(-10px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      padding: 3px;

      .tip-icon {
        font-size: 20px;
      }
    }

    .content {
      margin-left: 5px;
      letter-spacing: 0.4px;
      margin-block: 4px;

      .title {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
      }

      .describe {
        margin-top: 4px;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
  .notice-action {
    color: #fff;
    // background-color: var(--yellow-7);
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-align: right;
    line-height: 20px;
    padding-left: 12px;
    padding-right: 12px;
    border: none;
    &:hover {
      // background-color:var(--yellow-4);
      color: #FFFFFF !important;
    }
    &:active {
      // background-color: var(--yellow-7);
      color: #FFFFFF !important;
    }
  }
}

.normal {
  background: var(--primary-bg-4);
  border: 2px solid var(--primary-border);

  .tips-icon {
    background-color: var(--primary-bg-3);
  }

  .tip-icon {
    color: var(--primary);
  }
}

.urgent {
  background: var(--yellow-bg);
  border: 2px solid var(--yellow-2); 
  .tips-icon {
    background-color: var(--yellow-icon-bg);
  }

  .tip-icon {
    color: var(--yellow-2);
  }
  .notice-action{
    background-color: var(--yellow-7);
    &:hover {
      background-color:var(--yellow-4);
    }
    &:active {
      background-color: var(--yellow-7);
    }

  }
}
.rule {
  padding: 13px 11px;
  .tips {
    .tips-icon {
      align-self: flex-start;
      margin-right: 4px;
    }
    .content {
      margin: 0;
      .title {
        font-weight: 400;
        font-size: 14px;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 19px;
      }
    }
  }
  .notice-close {
    cursor: pointer;
    align-self: flex-start;
    svg {
      width: 20px;
      height: 20px;
      fill: var(--dark-3);
    }
  }
}
</style>