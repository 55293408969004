<template>
    <div class="deal-table">
        <artmisads-table
            :columns="columns"
            :data-source="data"
            >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'type'">
                    <div v-if="record.type === 'Deal'">
                        <span style="font-weight: 600;font-size: 14px;">
                            <artemisads-icon name="deal" style="font-size: 20px;vertical-align: -0.2em;font-weight: 500;margin-right: 8px;" />
                            Deal
                        </span>
                    </div>
                    <div v-if="record.type === 'Clippable Coupon'">
                        <span style="font-weight: 600;font-size: 14px;">
                            <artemisads-icon name="a-ClippableCoupon" style="font-size: 20px;vertical-align: -0.2em;font-weight: 500;;margin-right: 8px;" />
                            Clippable Coupon
                        </span>
                    </div>
                    <div v-if="record.type === 'Promo Code'">
                        <span style="font-weight: 600;font-size: 14px;">
                            <artemisads-icon name="a-PromoCode" style="font-size: 20px;vertical-align: -0.2em;font-weight: 500;;margin-right: 8px;" />
                            Promo Code
                        </span>
                    </div>
                </template>
                <template v-if="column.key === 'status'">
                    <div style="display: inline-block;">
                        <a-popover placement="rightTop" :color="'#333'">
                            <template #content>
                                <div style="padding: 10px;color:#fff">
                                    <p style="color: #fff;">SystemTime: {{moment().local().format('MM/DD/YYYY, h:mm A')}}</p>
                                    <p style="color: #fff;">{{ getTimeZoneStr(user?.userInfo?.countryCode || countryCode) }} Time: {{ moment().tz(timezone).format('MM/DD/YYYY, h:mm A') }}</p>
                                    <p style="color: #fff;">Deal Start ({{ getTimeZoneStr(user?.userInfo?.countryCode || countryCode) }}):  {{ moment(record.beginTime).tz(timezone).format('MM/DD/YYYY, h:mm A') }}</p>
                                    <p style="color: #fff;">Deal End ({{ getTimeZoneStr(user?.userInfo?.countryCode || countryCode) }}): {{ moment(record.endTime).tz(timezone).format('MM/DD/YYYY, h:mm A') }}</p>
                                </div>
                            </template>
                            <div v-if="record.status === 'Active'" style="display: flex;flex-wrap: wrap;row-gap: 8px;">
                                <div class="coupon-status">
                                    <artemisads-icon name="duigou-jiacu" style="color: #42B577;margin-right: 8px;font-size: 16px" ></artemisads-icon> 
                                    <span>Active {{ moment(record.beginTime).tz(timezone).format('MM/DD') }} ~ {{ moment(record.endTime).tz(timezone).format('MM/DD') }}</span>
                                </div>
                                <div class="promo-code" v-if="record.type === 'Promo Code'" @click="() => onCopy(record.promoCode)">
                                    <div class="promo-code-title">
                                        <p>PROMO</p>
                                        <p>CODE</p>
                                    </div>
                                    <div class="promo-code-brand">
                                        <p>{{ record.promoCode }}</p>
                                    </div>
                                    <div>
                                        <artemisads-icon name="copy2" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="record.status === 'Upcoming'" style="display: flex;flex-wrap: wrap;row-gap: 8px;">
                                <div class="coupon-status">
                                    <artemisads-icon name="jindu-cu" style="color: #E68303;margin-right: 8px;font-size: 16px;" ></artemisads-icon> 
                                    <span>Upcoming {{ moment(record.beginTime).tz(timezone).format('MM/DD') }} ~ {{ moment(record.endTime).tz(timezone).format('MM/DD') }}</span>
                                </div>
                                <div class="promo-code" v-if="record.type === 'Promo Code'" @click="() => onCopy(record.promoCode)">
                                    <div class="promo-code-title">
                                        <p>PROMO</p>
                                        <p>CODE</p>
                                    </div>
                                    <div class="promo-code-brand">
                                        <p>{{ record.promoCode }}</p>
                                    </div>
                                    <div>
                                        <artemisads-icon name="copy2" />
                                    </div>
                                </div>
                            </div>
                        </a-popover>
                    </div>
                </template>
                <template v-if="column.key === 'priceOff'">
                    <div class="coupon-price">
                        <p class="full-price">{{ record.currencySymbol }}{{ record.fullPrice.toFixed(2) }}</p>
                        <p class="final-price">{{ record.currencySymbol }}{{ record.finalPrice.toFixed(2) }}</p>
                        <p class="price-off">{{ record.priceOff.toFixed(1) }}% off</p>
                    </div>
                </template>
                <template v-if="column.key === 'action'">
                    <artmisads-button class="edit-button" type="default" @click="() => onEdit(index,record)">Edit</artmisads-button>
                </template>
            </template>
        </artmisads-table>
    </div>
</template>

<script setup>
import moment from 'moment'
import { ref, watch } from 'vue'
import { COUNTRY_TIME_TZ } from '~/contants/common.js'
import { copyToClipboard, getTimeZoneStr } from '~/utils';
import { useUserInfoStore } from "@/storeData";

const emit = defineEmits(['edit'])
const props = defineProps(['dataSource', 'countryCode', 'isCooperator', 'showEdit']);
const data = ref(props.dataSource);
const user = useUserInfoStore();
const timezone = COUNTRY_TIME_TZ[props.countryCode || user.userInfo.countryCode]

watch(
  () => props.dataSource,
  (newValue) => {
    data.value = newValue;
  }
)

const columns = [
  {
    title: "Deal Type",
    key: "type",
    dataIndex: "type",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    width: props.isCooperator ? 240 : 400
  },
  {
    title: "Price",
    key: "priceOff",
    dataIndex: "priceOff",
  },
]

if (props.showEdit) {
    columns.push({
        title: 'Action',
        key: 'action',
    })
}

const onEdit = (index, record) => {
    emit('edit', index, record)
}

const onCopy = (v) => {
    copyToClipboard(v);
}
</script>

<style lang="less" scoped>
.deal-table {
    // border: 1px solid var(--border-1);
    border-radius: 8px;
    overflow: hidden;
    :deep(.ant) {
        &-table-cell {
            padding: 8px;
        }
    }
    .edit-button {
        height: 24px;
        min-width: auto;
        font-size: 13px;
        padding: 0 13px;
        line-height: 24px;
        border-radius: 6px;
        border: 1px solid var(--border-1);
        box-shadow: 0 1px 0 0 var(--box-shadow-2);
    }
}
.coupon-status {
    display: flex;
    padding: 2px 6px;
    overflow: hidden;
    border-radius: 6px;
    align-items: center;
    justify-content: left;
    background: var(--bg-gray-8);
    &:hover {
        background: var(--bg-gray-5);
    }
}
.promo-code {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-1);
    border-radius: 6px;
    padding: 0px 6px 0 2px;
    box-sizing: border-box;
    background: var(--color-white);
    height: 24px;
    cursor: pointer;
    .anticon {
            color: var(--dark-4);
            margin-left: 4px;
        }
        &:hover {
         .anticon {
            color: var(--dark-2)
         }   
    }
    .promo-code-brand {
        height: 24px;
        font-size: 13px;
        font-weight: 600;
        line-height: 24px;
        margin-right: 2px;
        padding-left: 8px;
        border-left: 1px solid var(--border-1);
    }
    .promo-code-title{
        height: 24px;
        display: flex;
        flex-wrap: wrap;
        color: var(--dark-3);
        line-height: 12px;
        width: 44px;
        p {
            font-size: 12px;
            transform: scale(0.8);
            font-weight: 500;
            letter-spacing: 0.2px;
            color: var(--dark-3);
            margin-top: 1px;
            &:last-child {
                margin-top: -3px;
                letter-spacing: 3.3px;
                color: var(--dark-3);
            }
        }
    }
}
.coupon-price {
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    .full-price {
        margin-right: 8px;
        color: var(--dark-3);
        text-decoration: line-through;
    }
    .final-price {
        font-weight: 500;
        color: var(--dark-1);
    }
    .price-off {
        padding: 0 6px;
        font-size: 12px;
        margin-left: 8px;
        font-weight: 400;
        border-radius: 4px;
        color: var(--red-text);
        background: var(--red-down);
        border: 1px solid rgba(242, 85, 68, 0.4);
    }
}
</style>

