<template>
  <div class="information">
    <div :class="'top-section' + (imageUrl ? '' : ' show-bg')">
      <a target="_blank" :href="productUrl"
        ><img :src="imageUrl" v-if="imageUrl"
      /></a>
      <div class="title">
        <h1>{{ title }}</h1>
        <a target="_blank" :href="productUrl"
          >Amazon Detail Page<svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-tiaozhuan"></use></svg></a>
      </div>
    </div>
    <div class="info">
      <div class="info-body">
        <p>
          <span>ASIN:</span>
          <span>{{ asin }}</span>
        </p>
        <p>
          <span>Brand:</span>
          <span>{{ brandName }}</span>
        </p>
        <p>
          <span>Marketplace:</span>
          <span>
            <span class="marketplace">
              <svg class="icon" aria-hidden="true" style="font-size: 20px">
                <use :xlink:href="MARKET_PLACE_MAP[countryCode]?.icon"></use>
              </svg>
              <span>{{ MARKET_PLACE_MAP[countryCode]?.label }}</span>
            </span>
          </span>
        </p>
        <p>
          <span>Category:</span>
          <span>{{ category }}</span>
        </p>
        <p>
          <span>Price:</span>
          <price-deal-layout v-if="combineDeal" :record="combineDeal" />
          <span v-else>{{ currencySymbol }}{{ Number(price).toFixed(2) }}</span>
        </p>
        <p>
          <span>Availiability:</span>
          <span>{{ availiabilityText }}</span>
        </p>
        <p>
          <span>Commission:</span>
          <span class="commission">
            <span>{{ commission }}%</span>
          </span>
        </p>
        <p>
          <span>Best Seller Rank:</span>
          <span>{{ bestSellerRank }}</span>
        </p>
        <p>
          <span>Est. Payout:</span>
          <span>{{ currencySymbol }}{{ Number(estPayout).toFixed(2) }}</span>
        </p>
        <p>
          <span>Rating:</span>
          <span>{{ productStarRating }}</span>
        </p>
        <p v-if="otherCountryCodes?.length" style="align-items: center">
          <span>Other Marketplace:</span>
          <span class="other-countries">
            <a
              href="javascript:;"
              v-for="cc in otherCountryCodes"
            >
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="MARKET_PLACE_MAP[cc.countryCode]?.icon"></use>
              </svg>
            </a>
          </span>
        </p>
        <p>
          <span># of Ratings:</span>
          <span>{{ productNumRatings }}</span>
        </p>
      </div>
      <slot name="deals"></slot>
      <slot name="link"></slot>
      <CommissionChartModal
        v-model:open="open"
        :productId="productId"
        :countryCode="countryCode"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import { MARKET_PLACE_MAP } from '~/contants/publisher'
import PriceDealLayout from '~/components/PriceDealLayout'
import CommissionChartModal from './commissionHistoryChartModal.vue'

const productId = inject('productId')

const {
  imageUrl,
  title,
  productUrl,
  asin,
  brandName,
  brandId,
  category,
  categoryId,
  categoryLevel,
  currencySymbol,
  price,
  availiability,
  commission,
  productStarRating,
  estPayout,
  productNumRatings,
  countryCode,
  otherCountryCodes,
  bestSellerRank,
  combineDeal,
} = defineProps([
  'imageUrl',
  'title',
  'productUrl',
  'asin',
  'brandName',
  'brandId',
  'category',
  'categoryId',
  'categoryLevel',
  'currencySymbol',
  'price',
  'availiability',
  'commission',
  'productStarRating',
  'estPayout',
  'productNumRatings',
  'countryCode',
  'otherCountryCodes',
  'bestSellerRank',
  'combineDeal',
])

const availiabilityText = computed(() => {
  if (availiability) {
    return availiability
      .toLowerCase()
      .split('_')
      .map((value) => value.charAt(0).toUpperCase() + value.slice(1))
      .join(' ')
  } else {
    return ''
  }
})

const onGoToProductList = () => {
  localStorage.setItem(
    'previewCategory',
    `${countryCode}_${categoryId}_${categoryLevel}`
  )
}

const open = ref(false)
</script>

<style scoped lang="less">
@import '~/assets/less/ellipsis.less';

.information {
  .top-section {
    display: flex;
    > a {
      height: 160px;
      flex: 0 0 198px;
      text-align: center;
      border-radius: 6px;
    }
    &.show-bg {
      background-color: var(--bg-gray-2);
    }

    img {
      max-width: 198px;
      max-height: 160px;
      border-radius: 8px;
      object-fit: contain;
    }
    h1 {
      font-weight: 600;
      font-size: 16px;
      color: var(--dark-1);
      letter-spacing: 0.3px;
      line-height: 22px;
      margin-top: 6px;
      width: 100%;
      max-height: 66px;
      margin-bottom: 16px;
      .multiple-ellipsis(3);
    }
    .title {
      margin-left: 24px;
      > a {
        color: var(--dark-1);
        background: var(--bg-gray-8);
        border-radius: 8px;
        padding: 0 8px;
        height: 28px;
        display: inline-flex;
        line-height: 28px;
        font-size: 16px;
        font-weight: 500;
        align-items: center;
        &:hover {
          background: var(--bg-gray-5);
        }
        > .icon {
          margin-left: 8px;
          font-size: 20px;
        }
      }
    }
  }

  .info {
    margin-top: 24px;

    &-body {
      gap: 16px;
      gap: 0 100px;
      height: 240px;
      display: grid;
      position: relative;
      grid-template-columns: 1fr 1fr;

      &::after {
        content: '';
        display: block;
        width: 1px;
        background: var(--border-4);
        position: absolute;
        left: 50%;
        top: 10px;
        height: calc(100% - 20px);
      }

      > p {
        height: 40px;
        display: flex;
        justify-content: space-between;

        > span {
          font-size: 14px;
          color: var(--dark-3);
          letter-spacing: 0.4px;
          align-self: center;
          font-weight: 500;

          &:last-child {
            font-weight: 600;
            text-align: right;
            line-height: 20px;
            color: var(--dark-1);
          }

          > a {
            color: var(--dark-1);
            text-decoration: underline;
          }
        }

        .marketplace {
          display: flex;
          height: 20px;
          align-items: center;
          gap: 4px;
          padding: 0 6px;
          background-color: var(--bg-gray-8);
          border-radius: 4px;

          span {
            margin-top: -2px;
            line-height: 20px;
            position: relative;
            color: var(--dark-2);
          }
        }

        .commission {
          display: flex;
          align-items: center;
          gap: 10px;

          > span {
            border-radius: 4px;
            background-color: var(--primary);
            line-height: 20px;
            font-size: 14px;
            color: var(--color-white);
            font-weight: 700;
            letter-spacing: 0.4px;
            padding: 0 6px;
          }

          .history {
            border: none;
            padding: 0;
            line-height: 32px;
            display: flex;
            box-shadow: none;
            color: var(--dark-3);

            .iconfont {
              height: 26px;
              font-size: 20px;
              vertical-align: middle;
            }

            :deep(svg) {
              width: 20px;
              height: 20px;
              fill: var(--dark-3);
            }
          }
        }
      }

      .other-countries {
        border-radius: 4px;
        background-color: var(--bg-gray-8);
        > a {
          height: 24px;
          padding: 0 8px;
          margin-right: -4px;
          display: inline-block;
          &:last-of-type {
            margin-right: 0;
          }
          &:hover {
            border-radius: 4px;
            background-color: var(--bg-gray-4);
          }
          &:active {
            background-color: var(--bg-gray-5);
          }
          > .icon {
            font-size: 20px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
</style>