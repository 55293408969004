<template>
  <main class="main_container">
    <div class="product">
      <pulic-report-notice type="urgent" v-if="info.status === 'inactive'">
        <template #title>Product it not active</template>
      </pulic-report-notice>
      <Information class="box" v-bind="info">
        <template #deals>
          <a-skeleton :loading="spinning">
            <div
              style="margin-top: 28px; margin-bottom: 28px"
              v-if="info.productDeals?.length"
            >
              <div class="deal-title">Deals</div>
              <ProductDeals
                :isCooperator="true"
                :dataSource="info.productDeals"
                :countryCode="info.countryCode"
              />
              <div class="subtitle">
                Note: The deals are provided by the seller themselves and
                haven't undergone verification. It is advisable to confirm the
                details of the deal with the seller prior to any promotion.
              </div>
            </div>
          </a-skeleton>
        </template>
      </Information>
      <div class="track-link" v-if="productTrackLink">
        <h2>Generate a Link</h2>
        <div class="fw500 camref">
          <span>* Camref</span>
          <artmisads-input
            size="middle"
            :value="cooperatorInfo.cooperationKey"
            disabled
          />
          <p v-if="!cooperatorInfo.cooperationKey" class="warning-tip">
            <i class="iconfont icon-a-warring-tianchong3" />Camref is required.
          </p>
        </div>
        <div class="fw500 link-textarea">
          <span
            :class="
              'link-copy-btn' +
              (cooperatorInfo.cooperationKey ? '' : ' disabled')
            "
            @click="copyTrackLink"
          >
            <svg class="icon" aria-hidden="true" style="font-size: 22px">
              <use xlink:href="#icon-copy2"></use>
            </svg> </span
          >{{
            productTrackLink
              ? productTrackLink[
                  (productTrackLink.shortLinkSelect ? 'short' : 'tracking') +
                    'Link'
                ]
              : ''
          }}
        </div>
        <a-checkbox
          class="short-link"
          v-model:checked="productTrackLink.shortLinkSelect"
          >Short Link</a-checkbox
        >
      </div>
      <variations
        v-if="info.variationTotal"
        :id="productId"
        userType="cooperate"
        :showStatus="false"
        @updateProductId="updateProductId"
        :countryCode="props.cooperatorInfo.countryCode"
      />
    </div>
  </main>
</template>

<script setup>
import { provide } from 'vue'
import { message } from 'ant-design-vue'
import { copyToClipboard } from '~/utils'
import { cooperatorApi } from '~/server/index'
import { onMounted, ref, watch, defineProps } from 'vue'

import Information from './information.vue'
import Variations from '~/components/variations.vue'
import ProductDeals from '~/components/ProductDeals.vue'
import PulicReportNotice from '~/components/PulicReportNotice.vue'

const productTrackLink = ref(null)
const props = defineProps(['productId', 'cooperatorInfo'])
const emit = defineEmits(['copyTrackLink'])
let productId = props.productId

provide('productId', productId)

const info = ref({})

const spinning = ref(true)

const getProductDetail = async (id) => {
  if (!productId && !id) return
  spinning.value = true

  const res = await cooperatorApi
    .getProductDetail(id ? id : productId, props.cooperatorInfo)
    .catch((e) => message.error('System error, please try again later.'))
  const { success, data } = res

  if (success) {
    if (id) {
      provide('productId', id)
      productId = id
    }

    info.value = data
  }

  if (productTrackLink.value?.trackingLink != undefined) {
    spinning.value = false
  }
}

function getTrackLink(id) {
  const params = {
    id,
    cooperationName: props.cooperatorInfo.cooperationName,
    countryCode: props.cooperatorInfo.countryCode,
  }

  if (props.cooperatorInfo.cooperationKey) {
    params['cooperationKey'] = props.cooperatorInfo.cooperationKey
  }

  cooperatorApi.getTrackLink(params).then((res) => {
    if (res.success) {
      productTrackLink.value = res.data
    } else {
      productTrackLink.value = { trackingLink: '' }
    }

    if (info.value.status) {
      // 详情已加载完毕
      spinning.value = false
    }
  })
}

// 复制链接
function copyTrackLink() {
  if (productTrackLink.value.trackingLink) {
    emit('copyTrackLink', {
      ...props.cooperatorInfo,
      ...productTrackLink.value,
    })
  }
}

function updateProductId(id) {
  info.value = {}
  getTrackLink(id)
  getProductDetail(id)
}

onMounted(() => {
  getProductDetail()
  getTrackLink(productId)
})

watch(
  () => props.productId,
  (val) => {
    info.value = {}
    getTrackLink(val)
    getProductDetail(val)
  }
)
</script>

<style scoped lang="less">
.main_container {
  .skeleton-loading {
    :deep {
      .ant-skeleton-image {
        width: 300px;
        height: 300px;
        line-height: 300px;
      }
    }
  }
}

.track-link {
  margin-top: 40px;
  > h2 {
    height: 27px;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 16px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    background: var(--color-white);
  }
  > .camref {
    font-size: 14px;
    line-height: 20px;
    color: var(--dark-2);
    letter-spacing: 0.4px;
    > .ant-input {
      font-size: 14px;
      margin-top: 6px;
      font-weight: 500;
      color: var(--dark-1);
      background: var(--bg-gray-2);
      &:hover {
        border: var(--border);
      }
    }
  }
  .link-textarea {
    height: 120px;
    font-size: 14px;
    margin-top: 24px;
    overflow-y: auto;
    user-select: none;
    position: relative;
    border-radius: 8px;
    margin-bottom: 15px;
    word-break: break-word;
    padding: 8px 72px 8px 12px;
    background: var(--bg-gray-2);
    border: 1px solid var(--border-1);
  }
  .short-link {
    width: 100%;
    justify-content: right;
  }
}

.link-copy-btn {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  width: 56px;
  height: 64px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 8px;
  &.disabled {
    cursor: not-allowed;
    svg {
      fill: var(--dark-4);
    }
    &:hover > svg {
      fill: var(--dark-4);
    }
  }

  svg {
    fill: var(--dark-3);
  }
  &:hover > svg {
    fill: var(--dark-2);
  }
  &:active > svg {
    fill: var(--dark-1);
  }
}

.product {
  display: flex;
  flex-direction: column;
  :deep(.report-notice) {
    margin-bottom: 0;
  }
  :deep(.product-variation) {
    > h2 {
      height: 28px;
      border: none;
      margin: 18px 0;
      line-height: 28px;
    }
    .header {
      height: 32px;
      margin-bottom: 16px;
    }
  }
}
.deal-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-1);
  letter-spacing: 0.5px;
  margin-bottom: 14px;
}
.subtitle {
  margin-top: -8px;
  color: var(--dark-3);
}
</style>